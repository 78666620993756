import React from 'react';
import GreenSubmitButton from '../buttons/greenSubmitButton';
import AppColors from '../assets/colors';
import { inject, observer } from "mobx-react";
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
// Translations
import _useTranslate from '../hooks/_useTranslate';
import {TABLE_QR_ORDERING_ONLY, QR_READ_ONLY} from "../assets/globalConstants";


const CategoryProductCard = ({ image, title, description, price, oldPrice,
  onClick, badges, soldOut, feathersStore })=> {

  let cart = _useTranslate(feathersStore.language, "cart");

  const getPrice = ()=> {
    var fixedPrice = parseFloat(price)
    return fixedPrice.toFixed(2)
  }

  const getOldPrice = ()=> {
    var fixedPrice = parseFloat(oldPrice)
    return fixedPrice.toFixed(2)
  }

  return(
    <div className="product-card" onClick={onClick}>
       {(soldOut) && (      
          <div className="soldOutLabelContainer">
            <div className="label">{cart.soldOut}</div>
          </div>)     
        }
        {(badges && badges.includes('new')) &&  (
          <div className="newLabelContainer">
            <div className="label">{cart.new}</div>
          </div>)
        }
        {(badges && badges.includes('sale')) && (  
          <div className="discountLabelContainer">
            <div className="label">{cart.sale}</div>
          </div>)
        }
        {(badges && badges.includes('hot')) && (     
          <div className="hotLabelContainer">
            <div className="label"><LocalFireDepartmentOutlinedIcon/></div>
          </div>)
        }
      <div className="offer-view">
      <div 
        className="cardPic" 
        style={{backgroundImage: `url(${image})`}}>               
      </div>
      <div className="categoryObjectText">
        <div className="product-title-outter">
          < div className="product-title-text" >{title}</div>
        </div>
        {description ? <span className="description-text">{description}</span> : null}
        <div className="card-price-container">
          <div className="price-field">{getPrice()}{feathersStore.currency}</div>
          {(oldPrice) && (<div className="erased-price"><del>{getOldPrice()}{feathersStore.currency}</del></div>)}
        </div>
      </div>
      { !QR_READ_ONLY &&
        <div className="categoryObjectButton">
          <GreenSubmitButton
            width="100%"
            size="small"
            borderRadius={2}
            marginLeft={0.5}
            marginRight={0.5}
            marginBottom={0.5}
            disabled={soldOut || (TABLE_QR_ORDERING_ONLY && (!feathersStore.tableId?.length > 0)) || !feathersStore.restaurantOpen}
            title={cart.select}
            onClick={onClick}
          />
        </div>
      }
      </div>
    </div>
  )
}
    

export default inject('feathersStore')(observer(CategoryProductCard));