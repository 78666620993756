import React, { useState, useEffect, Fragment } from 'react';
import {API_URL, SHOW_REVIEWS, TABLE_QR_ORDERING_ONLY, QR_READ_ONLY} from "../assets/globalConstants";
import ButtonPlus from '../buttons/buttonPlus'
import ButtonMinus from '../buttons/buttonMinus'
import AttributeButtonPlus from '../buttons/attributeButtonPlus'
import AttributeButtonMinus from '../buttons/attributeButtonMinus'
import AddToCartButton from '../buttons/addToCartButton';
import FavoriteButton from '../buttons/favoriteButton';
import SmallAlert from '../modals/smallAlert';
import { TextareaAutosize } from '@mui/material';
import { inject, observer } from "mobx-react";
import { CircularProgress } from '@mui/material';
import { useNavigate, useSearchParams  } from 'react-router-dom';
import ProductCard from '../cards/productCard';
import SectionTitle from "../cards/sectionTitle"
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import NoneEvaluation from '../cards/noneEvaluation';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import Avatar from '@mui/material/Avatar';
import RatedCard from '../cards/ratedCard';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { CSSTransition } from 'react-transition-group';
import Colors from "../assets/colors";
import UnregisteredEmptyRating from "../cards/unregisteredEmptyRating";
import RegisteredEmptyRating from "../cards/registeredEmptyRating";
import GreenSubmitButton from '../buttons/greenSubmitButton';
import * as cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import useWindowDimensions from '../hooks/useWindowDimensions';

// Translations
import _useTranslate from '../hooks/_useTranslate';

const GoldRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

const ProductDetailCard = ({
  addToFavorite, 
  favoriteColor,
  image,
  title,
  price,
  oldPrice,
  description,
  sizes,
  renderSizes,
  ingredients,
  renderIngredients,
  extras,
  renderExtras,
  attributes,
  renderAttribute,
  pressMinus,
  pressPlus,
  quantity,
  addToCart,
  badges, 
  soldOut,
  cartButtonDisabled,
  comments,
  setComments,
  weighted,
  weightedPrice,
  weightedQuantity,
  onPressIncreaseWeighted,
  onPressDecreaseWeighted,  
  productId,
  averageStars,
  setAverageStars,
  reviews,
  setReviews,
  category1,
  pressMinusAttribute,
  pressPlusAttribute,
  disabledQTableQuantityButtons,
  feathersStore
})=> {

  let common = _useTranslate(feathersStore.language, "common");
  let cart = _useTranslate(feathersStore.language, "cart");

  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();  

  const[relatedProducts, setRelatedProducts] = useState([]);
  const [cartIncreased, setCartIncreased] = useState(false);
  const [warning, setWarning] = useState(false);
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [stars, setStars] = useState(1);
  const [reviewText, setReviewText] = useState("");
  const [purchased, setPurchased] = useState(false); 
  const [showQuantity, setShowQuantity] = useState(5);

  useEffect(() => {    
    feathersStore?.isAuthenticated && getFeathers();        
  }, [feathersStore?.isAuthenticated, category1, feathersStore.productCreatedEvent
    , feathersStore.productUpdatedEvent]);

  const getFeathers = async() => {
    let cartFromStorage = JSON.parse(localStorage.getItem('fdwa_cart')) || [];
    let ids = cartFromStorage.map(item=> item.product_id)
    const feathersCategory1Products =  await feathersStore.productsPerCategory1(Array.isArray(category1) ? +category1[0]?.id : +category1?.id); 
    const feathersAllProducts = await feathersStore._products();
    const shuffledAllProducts = feathersAllProducts.sort(() => 0.5 - Math.random()).slice(0,5);
    let mergedRelatedProducts =  concat(feathersCategory1Products, shuffledAllProducts) 
    mergedRelatedProducts.forEach( prod =>{
      if(ids.includes(prod._id)) prod.added = true;
    })     
    setRelatedProducts([...mergedRelatedProducts]);
  }

  useEffect(() => {
    productId && feathersStore.user?.firstname !== "default" && checkIfPurchased();
  }, [productId, feathersStore.user]);

  useEffect(() => {
    if(width < 650)setShowQuantity(4);
    else if(width < 992)setShowQuantity(3);
    else if(width < 1250)setShowQuantity(4);
    else setShowQuantity(5);
  }, [width])

  const getPrice = ()=> {
    var fixedPrice = parseFloat(price)
    return fixedPrice.toFixed(2)
  }

  const getOldPrice = ()=> {   
    var fixedPrice = parseFloat(oldPrice)
    return fixedPrice.toFixed(2)
  }

  const onChangeText = event => {
    setComments(event.target.value)
  }

  const addRelatedToCart = (item) => {    
    let cart = [];
    let cartBiggestId = 0;
    if(localStorage.getItem('fdwa_cart')){
      cart = JSON.parse(localStorage.getItem('fdwa_cart'));    
      if(cart.length > 0)
        cartBiggestId = +cart.map(e => e.id).sort().reverse()[0] + 1;         
    } 
    const pickedSize = item.sizes.filter(size => size.default)[0];   
    pickedSize && Object.assign(pickedSize, {nameTranslated: feathersStore._translateNameAttr(pickedSize)});
    const cartItem = {
      id: cartBiggestId,
      product_id: item._id,
      product_name: item.name,
      product_nameTranslated: feathersStore._translateName(item),
      product_size: pickedSize,
      product_image: `${API_URL}/images/${item?.images[0]}`,
      product_price: item[packagePricing() ? 'packagePrice' : 'retailPrice'],
      product_totalPrice: parseFloat(item[packagePricing() ? 'packagePrice' : 'retailPrice']),
      product_netPrice: calculateNetPrice(item),
      product_quantity: 1,
      product_extraSum: 0,
      features: [],
      ingredients: [],
      attributes: [],  
      product_printers: item.printers,
      product_kdsSections: item.kdsSections,
      product_vat: item[packagePricing() && item?.packageVat ? 'packageVat' : 'vat'],
      product_stock: item.stock,
      product_handleStock: item.handleStock,
      product_comments: item.comments,
      nameTranslations: item?.nameTranslations,
      shortDescriptionTranslations: item?.shortDescriptionTranslations
    }
    if (feathersStore.checkOutOfStock(item._id, item.handleStock, item.stock, cartItem.product_quantity, cart)){
      setWarning(true); return;
    } 
    cart.push(cartItem);
    feathersStore.setCartLength(feathersStore.cartLength + 1); 
    localStorage.setItem("fdwa_cart", JSON.stringify(cart));
    setCartIncreased(true); 
    let clonedRelatedProducts = [...relatedProducts];
    clonedRelatedProducts.forEach(prod => {
      if(prod._id === item._id)prod.added = true;
    })     
    clonedRelatedProducts.filter(prod => prod.added) && setRelatedProducts([...clonedRelatedProducts]);  
  }

  const packagePricing = () => {
    return  feathersStore.settings?.deliveryPricing && !feathersStore?.tableId && !(TABLE_QR_ORDERING_ONLY && QR_READ_ONLY);
  }

  const calculateNetPrice = item => {
    const label = feathersStore.settings.vats
      ?.find(v => +v.id === +item[packagePricing() && item?.packageVat ? 'packageVat' : 'vat'])?.label
    return +((item[packagePricing() ? 'packagePrice' : 'retailPrice']) / (1 + label / 100 )).toFixed(2);
  }

  const goToProduct =  product =>  {    
    navigate(`/product/${feathersStore._translateName(product)}`,{
      state: {id: `${product._id}`,  payload: product}     
    });
  }

  const checkIfPurchased = async() => {
    const orders = await feathersStore.ordersPerUser();
    const check = orders?.map(order => order.items).flat().find(prod => prod.product_id === productId);
    setPurchased(check);
  }

  const renderComments = () =>  reviews?.filter(rev => !rev.blocked)
    .map((review, index) =>
      <RatedCard 
        key={index}
        avatar={review.avatar}
        username={review.username}
        date={new Date(review.date).toLocaleDateString()}
        stars={review.stars} 
        comment={review.reviewText}
      />
  )

  const saveReview = async() => {
    const now = new Date();
    const review = {
      date: now,
      userId : feathersStore.user?._id,
      avatar: feathersStore.user?.avatar,
      username: feathersStore.user?.firstname + " " + feathersStore.user?.lastname,
      stars: stars,
      reviewText: reviewText,
      productImage: image,
      blocked: false,
      productId: productId,
      productName: title,
      isNew: true
    }  
    let newCommentsList = concat(review, [...(reviews || [])]);
    const newStarAverage = calculateStarAverage(newCommentsList); 
    await feathersStore.patchProduct(productId, {commentsList: newCommentsList, starAverage: newStarAverage});

    let clonedUser = cloneDeep(feathersStore.user);
    if(clonedUser?.commentsList){
      const newArr = concat(review, clonedUser?.commentsList) ;
      Object.assign(clonedUser, {commentsList: newArr})
    }
    else clonedUser.commentsList = new Array(review);
    await feathersStore._patchUser(clonedUser._id, {commentsList: clonedUser.commentsList});
    feathersStore.setUser(clonedUser);

    setStars(1);
    setReviewText("");
    setReviews(newCommentsList);
    setAverageStars(newStarAverage);
  }

  const calculateStarAverage = (commentsArray) => {
    let cleanArray = commentsArray
        .filter(comm => !comm.blocked);
    let starsTotal = cleanArray.map(c => c.stars)
        .reduce((a,b) => a+b, 0);
    return starsTotal / cleanArray.length;
  }

  const renderProductItem = (item, index) => (
    <ProductCard
      key={item._id}
      image={ item?.images[0] ? (`${API_URL}/images/` + item?.images[0]) : require("../img/200_spinner.gif")}
      title={feathersStore._translateName(item)}
      price={item[packagePricing() ? 'packagePrice' : 'retailPrice']}
      oldPrice={item.oldPrice}
      badges={item.badges}
      soldOut={item.soldOut}
      weighted={item.weighted}
      onClick={() => addRelatedToCart(item, index)}
      added={item.added}  
      handleClick={() => goToProduct(item)}     
    />
  )

  return (
    <>
    <div className="productDetailsContainer"> 
      <div className="product-details-section">
        <div className="productDetailsPic" style={{backgroundImage: `url(${image})`}}>
          {(soldOut) && (      
            <div className="soldOutLabelContainer">
              <div className="label-detail">{cart.soldOut}</div>
            </div>)     
          }
          {(badges && badges.includes('new')) &&  (
            <div className="newLabelContainer">
              <div className="label-detail">{cart.new}</div>
            </div>)
          }
          {(badges && badges.includes('sale')) && (  
            <div className="discountLabelContainer">
              <div className="label-detail">{cart.sale}</div>
            </div>)
          }
          {(badges && badges.includes('hot')) && (     
            <div className="hotLabelContainer">
              <div className="label-detail"><LocalFireDepartmentOutlinedIcon/></div>
            </div>)
          }    
          <FavoriteButton onClick={addToFavorite} color={favoriteColor}/>
        </div>
      </div>       
      <div className="product-details-section padd-left-2">      
        <div className="productDetailsTitle">
          <div className="product-details-title">{title}</div>
          <div className="product-price-container">
            <div className="product-price-field">{getPrice()}{feathersStore.currency}</div>
            { oldPrice && (<div className="product-erased-price"><del>{getOldPrice()}{feathersStore.currency}</del></div>)}
          </div>
        </div>
        {description ?
        <div className="productDetailsDescription">
          {description}
        </div> : null
        }     
        { weighted ? 
          <div className="weighted-container">
            <div className="caption-for-weighted">{common.weighted}</div>
            <div className="caption-for-picker">{common.kgr}: {weightedPrice}&nbsp;{feathersStore.currency}/Kgr</div>           
            <div className="weight-buttons">
              <ButtonMinus onClick={onPressDecreaseWeighted}/>
              <div className="quantity-number quantity-weighted">{weightedQuantity}</div>  
              <ButtonPlus onClick={onPressIncreaseWeighted}/>                    
            </div>              
          </div> 
        : null } 
        {sizes.filter(s => s.enabled)?.length > 1 ?
        <>
          <div className="features-title">{common.pickSize}</div>
          <div className="productDetailsSizesContainer">         
            {sizes.map(renderSizes)}         
          </div>
        </>
        : null} 
        {ingredients.length > 0 ?
        <>
          <div className="features-title">{common.removeIngredients}</div>
          <div className="productDetailsMaterials">               
            <div className="productDetailsSizesContainer">
            {ingredients.map(renderIngredients)}             
            </div>          
          </div>
        </>
        : null} 
        {extras.length > 0 ?
        <>
          <div className="features-title">{common.addExtra}</div>
          <div className="productDetailsSizesContainer">      
            {extras.map(renderExtras)}         
          </div>
        </>
        : null}
      { attributes?.map(( table, tableIndex ) =>  
      <Fragment key={tableIndex} >
        {table.eshop &&
          <>
          {table.savedAttributesArray?.length > 0 &&
            <div className="attribute-caption">
              <div className="features-title">{feathersStore._translateTitle(table)}</div> 
              {table?.mandatory && <span className="red-star">*</span>} 
              {table?.quantityEnabled && !QR_READ_ONLY &&
                <div className="product-attribute-quantity">
                  <AttributeButtonMinus className="attribute-quantity-button" 
                    disabled={disabledQTableQuantityButtons(+tableIndex)} onClick={pressMinusAttribute(+tableIndex)}/>
                  <span className="attribute-quantity-number">{table?.quantity}</span>
                  <AttributeButtonPlus className="attribute-quantity-button" 
                    disabled={disabledQTableQuantityButtons(+tableIndex)} onClick={pressPlusAttribute(+tableIndex)}/>
               </div>
              }  
            </div>                 
          }
          <div  className="productDetailsSizesContainer">        
            {
            table?.savedAttributesArray?.map(( item, index ) => 
              renderAttribute(item, index, tableIndex))                  
            }        
          </div>
          </>
        }
      </Fragment>
      )} 
      { !QR_READ_ONLY &&
        <TextareaAutosize          
          minRows={4}
          maxRows={6}
          value={comments}         
          onChange={onChangeText}                           
          placeholder={common.productComments}
          className="product-details-textarea"
        />   
      }
      { !QR_READ_ONLY &&
        <div className="productDetailsQuantity">
          <ButtonMinus onClick={pressMinus}/>
          <span className="quantity-number">{quantity}</span>
          <ButtonPlus onClick={pressPlus}/>        
        </div> 
      }     
      { !QR_READ_ONLY &&
        <div className="productDetailsButton">
          <AddToCartButton 
            disabled={cartButtonDisabled || !feathersStore.restaurantOpen || soldOut || (TABLE_QR_ORDERING_ONLY && (!feathersStore.tableId?.length > 0))}
            onClick={addToCart}
          />
        </div>
      }
    
    </div>
  </div>
  {SHOW_REVIEWS && feathersStore.settings?.showReviews &&
    <div className="product-evaluation-container">
      <div className="reviews-title-container">
        <span className="title-text">{common.reviews} ({reviews?.filter(c => !c.blocked).length || 0})
          {averageStars &&
          <> 
            <span>/</span>
            <GoldRating style={{marginLeft: 5}} name="read-only" 
              precision={0.1} value={averageStars} readOnly />
          </>         
          }</span>
        <div className="line-100"></div> 
      </div>
        {feathersStore.isAuthenticated && 
          <div className="product-evaluation-body">
          { reviews?.length === 0 && <NoneEvaluation message={common.noEvaluation}/>}
          { reviews.user?.firstname === "default" || !purchased && <UnregisteredEmptyRating/> }
          { reviews?.length === 0 && feathersStore.user?.firstname !== "default" && <RegisteredEmptyRating/>}
          { reviews && renderComments()}
        </div>
        }
        {purchased && feathersStore.isAuthenticated && feathersStore.user?.firstname !== "default" && 
          <>
          <div className="change-password">
            <div className="row-flex-start-no-wrap">
              <ListAltIcon style={{color: Colors.settingsText, fontSize: 30}}/>
              <h3 style={{color: Colors.settingsText}}>{common.leaveAComment}</h3>
            </div>
            {showCommentForm ?
              <ExpandLess style={{color: Colors.settingsText, fontSize: 35, marginRight: 10}} onClick={() => setShowCommentForm(false)} />
            :            
              <ExpandMore style={{color: Colors.settingsText, fontSize: 35, marginRight: 10}} onClick={() => setShowCommentForm(true)} />
            }
          </div>
          <CSSTransition
            in={showCommentForm}
            timeout={300}
            classNames="alert"
            unmountOnExit
          >
            <div className="product-evaluation-body">
              <Avatar
                avatar={feathersStore.user?.avatar}
              />
              <p>{feathersStore.user?.firstname} {feathersStore.user?.lastname}</p>
              <GoldRating
                name="simple-controlled"
                value={stars}
                onChange={(event, newValue) => {
                  setStars(newValue);
                }}
              />
              <TextareaAutosize 
                style={{paddingLeft: 5, fontSize: 18}} 
                aria-label="leave a comment" 
                minRows={3}
                maxRows={4}
                placeholder={common.leaveAComment} 
                value={reviewText}
                onChange={(event) => {
                  setReviewText(event.target.value);
                }}
              />
              <GreenSubmitButton aria-label='save review' color={Colors.reviewBtn}
                onClick={saveReview} title={common.saveReview}></GreenSubmitButton>
            </div>
          </CSSTransition>
        </>}
      </div>
    }
    {relatedProducts.length > 0 ?
      <SectionTitle title= {common.watchThis} related={true}/>
    : null
    }
    <div className="offers">
      {relatedProducts ?
        relatedProducts.length > 0 ? relatedProducts.slice(0, showQuantity).map(renderProductItem) 
        : null
      : 
      <div className="circular">
        <CircularProgress 
        color="secondary" 
        style={{ margin: 6 }} 
        size={'4rem'}            
        />
      </div>}
    </div>
    <div className="preFooter"/>
    <SmallAlert 
      show={cartIncreased} 
      mode="cart"
      heading={common.cartUpdatedAlert} 
      onClose={() => setCartIncreased(false)} 
      message={common.cartUpdated} 
    /> 
    <SmallAlert 
      show={warning} 
      mode="stock"
      heading={common.stockWarningHeader} 
      onClose={() => setWarning(false)}  
      message={common.stockWarning}
    />   
  </>
);
  
}

export default inject('feathersStore')(observer(ProductDetailCard));
