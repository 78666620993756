import React from 'react';
import { inject, observer } from "mobx-react";

const CheckoutComponent = ({sku, title, data, quantity, total, feathersStore})=> {

    return(
        <div className="checkout-order-table-component">
            <div className="component-header">
                <span >{sku} - {title} x {quantity}</span>
                <span>{total.toFixed(2)}{feathersStore.currency}</span>
            </div>
            <div className="component-data">
                {data}
            </div>
           
        </div>
    )
}

export default inject('feathersStore')(observer(CheckoutComponent));