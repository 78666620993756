import React from 'react';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import { CardActionArea } from '@mui/material';
import RemoveShoppingCart from '@mui/icons-material/RemoveShoppingCart';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { inject, observer } from "mobx-react";
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import {TABLE_QR_ORDERING_ONLY, QR_READ_ONLY} from "../assets/globalConstants";


// Translations
import _useTranslate from '../hooks/_useTranslate';

const ProductCard = ({ image, added, title, price, oldPrice, badges, soldOut, weighted, 
  onClick, handleClick, attributes, feathersStore, onlyToProduct})=> {

  let cart = _useTranslate(feathersStore.language, "cart");

  const getPrice = ()=> { 
    const floatPrice = parseFloat(price);   
    return floatPrice.toFixed(2);
  }

  const getOldPrice = ()=> {
    const floatOldPrice = parseFloat(oldPrice);   
    return floatOldPrice.toFixed(2);
  }

  const packagePricing = () => {
    return  feathersStore.settings?.deliveryPricing && !feathersStore?.tableId && !(TABLE_QR_ORDERING_ONLY && QR_READ_ONLY);
  }

  return (
        
    <div className="product-card">
       {(soldOut) && (      
            <div className="soldOutLabelContainer">
              <div className="label">{cart.soldOut}</div>
            </div>)     
          }
          {(badges && badges.includes('new')) &&  (
            <div className="newLabelContainer">
              <div className="label">{cart.new}</div>
            </div>)
          }
          {(badges && badges.includes('sale')) && (  
            <div className="discountLabelContainer">
              <div className="label">{cart.sale}</div>
            </div>)
          }
          {(badges && badges.includes('hot')) && (     
            <div className="hotLabelContainer">
              <div className="label"><LocalFireDepartmentOutlinedIcon style={{fontSize: 'calc(14px + 0.6vw)'}}/></div>
            </div>)
          } 
      <div className="offer-view">
      <div className="cardPic" 
        style={{backgroundImage: `url(${image})`}}
        onClick={handleClick}
      >             
      </div>
      <div className="cardInfo">
        <div className="product-title-outter">
          <div className="product-title-text" onClick={handleClick}>{title}</div>
        </div>
        {attributes?.length > 0 && 
          <div className="mandatory-attributes-container">
            {attributes.map((attribute, index) => (            
              <div key={index} className="list-items-attributeDetails">
                <div>{feathersStore._translateTitle(attribute)}&nbsp;</div>
                <div>{feathersStore._translateNameAttr(attribute)}&nbsp;</div>
                <div>{attribute[packagePricing() ? 'packagePrice' : 'price']}{feathersStore.currency}</div>
                <div className="favorite-red-star">*</div>
              </div>       
            )) }
          </div>
        }
        <div className="card-price-container">
          <div className="price-field">{getPrice()}{feathersStore.currency}</div>
          {oldPrice && (<div className="erased-price"><del>{getOldPrice()}{feathersStore.currency}</del></div>)}
        </div>
      </div>
      { !QR_READ_ONLY &&
        <CardActionArea disabled={!feathersStore.restaurantOpen}>
        {!added? 
          <div className={(soldOut || weighted || !feathersStore.restaurantOpen || (TABLE_QR_ORDERING_ONLY && (!feathersStore.tableId?.length > 0)) ? "cardButton cardButton-disabled" : "cardButton" ) } 
            onClick={soldOut || weighted || !feathersStore.restaurantOpen || (TABLE_QR_ORDERING_ONLY && (!feathersStore.tableId?.length > 0)) ? 
            null : onlyToProduct ? handleClick  : onClick} >
        
          {soldOut || weighted ? <RemoveShoppingCart style={{color: '#fff'}}/> : onlyToProduct ? 
          <ArrowForwardIcon 
            style={{color: '#fff'}}           
          /> : 
          <ShoppingCart 
            style={{color: '#fff'}}           
          />}
        
        </div>
        :
        <div className="cardButton-added">
            <div className="cart-button-text">{cart.addToCart}</div>
        </div>}
        </CardActionArea>
      }
    </div>
    </div>      
  );
  
}

export default inject('feathersStore')(observer(ProductCard));
