import { makeObservable, observable, action} from 'mobx';
import { feathers } from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import authentication from '@feathersjs/authentication-client'
import { rx } from 'feathers-reactive';
import * as cloneDeep from 'lodash/cloneDeep';
import { API_URL, ADMIN_ID, DEFAULT_EMAIL, DEFAULT_PSW } from "./assets/globalConstants";
import { toString } from 'diacritic-regex';
import { v4 as uuidv4 } from 'uuid';

const diacriticsMappings ={
  'α': 'αάΑΆ',
  'ε': 'εέΈΕ',
  'η': 'ηήΗΉ', 
  'ι': 'ιίΙΊϊιϊΪ',
  'ο': 'οόΟΌ',
  'υ': 'υύΥΎϋΫ',
  'ω': 'ωώΩΏ'  
}
class FeathersStore{  
   
    favoritesArray = [];
    wishlistId = "";
    chatId = "";
    hasLocationPermission= false;
    isAuthenticated= false;
    user = null;
    categories = [];
    settings = {};
    cartLength = 0;
    goToAddressHelper = false;
    orderItem = {};
    savedMap = new Map();
    searchCategory = {}; //active search category ;  
    language = "el";
    productUpdatedEvent = {};  
    tablePatchedEvent = {}; 
    userUpdatedEvent = {};
    productCreatedEvent = {}; 
    messageCreatedEvent = {}; 
    showCookies = true;
    tableId = null;
    restaurantOpen = true;
    showRestaurantMessage = true;
    discountsArray = [];
    currency = "€";
    
    constructor(){
      makeObservable(this,  {
        favoritesArray: observable,
        wishlistId: observable,
        hasLocationPermission: observable,
        isAuthenticated: observable,
        user: observable,
        categories: observable,
        settings: observable,
        cartLength: observable,
        goToAddressHelper: observable,
        orderItem: observable,
        searchCategory: observable, 
        language: observable, 
        productUpdatedEvent: observable,
        tablePatchedEvent: observable,
        userUpdatedEvent: observable,
        productCreatedEvent: observable,  
        messageCreatedEvent: observable, 
        showCookies: observable,
        savedMap: observable,
        chatId: observable,
        tableId: observable,  
        restaurantOpen: observable, 
        showRestaurantMessage: observable,
        discountsArray: observable,
        currency: observable,
        setFavoritesArray: action,
        setWishlistId: action,
        setHasLocationPermission: action,
        setIsAuthenticated: action,
        setUser: action,
        setCategories: action,
        setSettings: action,
        setObservables: action,
        setCartLength: action,
        setGoToAddressHelper: action,
        setOrderItem: action,
        setSearchCategory: action,
        setLanguage: action,
        setProductUpdatedEvent: action,
        setTablePatchedEvent: action,
        setUserUpdatedEvent: action,
        setProductCreatedEvent: action, 
        setMessageCreatedEvent: action,   
        setShowCookies: action,
        setSavedMap: action,
        setTableId: action,
        setChatId: action,
        setRestaurantOpen: action, 
        setShowRestaurantMessage: action,
        setDiscountsArray: action,
        setCurrency: action
      })
      this.fetchChatId();// used for anonymous chat 
    }

    setChatId = value => {
      this.chatId = value;
    }  

    setSavedMap = data => {
      const clonedData = cloneDeep(data);     
      this.savedMap = clonedData;
    }

    setOrderItem = data => {
      const clonedOrderItem = cloneDeep(this.orderItem);
      const mergedObject = {...clonedOrderItem, ...data};
      this.orderItem = mergedObject;
    }

    setGoToAddressHelper = value => {
      this.goToAddressHelper = value;
    }

    setFavoritesArray = value => {
      this.favoritesArray = value;
    }

    setWishlistId = value => {
     this.wishlistId = value;
    }

    setHasLocationPermission = value => {
      this.hasLocationPermission = value;
    }

    setIsAuthenticated = value => {
      this.isAuthenticated = value;
    }

    setUser = value => {
      this.user = value;
    }
    
    setCategories = value => {
      this.categories = value;
    }

    setSettings = value => {
      this.settings = value;
    }

    setCartLength = value => {
      this.cartLength = value;
    }

    setLanguage = value => {
      this.language = value;
      localStorage.setItem('Language', value);  
    }

    setShowCookies = data => {      
      this.showCookies = data;
    }

    setProductUpdatedEvent = data => {      
      this.productUpdatedEvent = data;
    }

    setTablePatchedEvent = data => {      
      this.tablePatchedEvent = data;
    }

    setUserUpdatedEvent = data => {      
      this.userUpdatedEvent = data;
    }

    setProductCreatedEvent = data => {      
      this.productCreatedEvent = data;
    }

    setMessageCreatedEvent = data => {      
      this.messageCreatedEvent = data;
    }  

    setSearchCategory = data => {      
      this.searchCategory = data;
    }

    setTableId = value => {
      this.tableId = value;
    }

    setRestaurantOpen = value => {      
      if(!value && this.restaurantOpen)this.setShowRestaurantMessage(true)
      this.restaurantOpen = value;
    }
    setShowRestaurantMessage = value => {
      this.showRestaurantMessage = value;
    }

    setDiscountsArray = value => {
      this.discountsArray = value;
    }

    setCurrency = value => {
      this.currency = value;
    }
   
    connect = async () => {

      //ESTABLISH FEATHERS CONNECTION

     // const options = {transports: ['websocket'],forceNew: true, pingTimeout: 3000, pingInterval: 5000};
      this.app = feathers();   
                     
      this.socket = io(API_URL,  {query: {chatId: this.chatId}});    
      // this.feathersAuthClient = require('@feathersjs/authentication-client').default;
 
        
        this.app
            .configure(socketio(this.socket)) 
           // .configure(feathers.socketio(this.socket))
            .configure( authentication({            
                storage: window.localStorage // To store our accessToken
            }))
            .configure(rx({                           // add feathers-reactive plugin
                idField: '_id'
            }));

        this.isConnecting = true;
    
      await this.setObservables();
      
      this.app.io.on('connect', async () => {
        this.isConnecting = false;
        try{
          await this.setObservables();
          console.log('authenticated after reconnection');
        }catch(error) {
          console.log('error authenticating after reconnection', error);
        };
      });

      this.app.io.on('disconnect', () => {
        console.log('disconnected');
        this.isConnecting = true;
      });

      this.app.service('products').on('updated', message => this.setProductUpdatedEvent(message));
      this.app.service('products').on('patched', message => this.setProductUpdatedEvent(message));
      this.app.service('products').on('created', message => this.setProductCreatedEvent(message));
      this.app.service('messages').on('created', message => this.setMessageCreatedEvent(message));
      this.app.service('tables').on('patched', message => this.setTablePatchedEvent(message));
      this.app.service('timer').on('updated', message => this.setRestaurantOpen(message));
      this.app.service('settings').on('patched', async message => await this.fetchSettings());
      this.app.service('users').on('patched', message => {
        if(message._id === this.user._id)this.setUserUpdatedEvent(message);
        this.setUser(message);
      });
      this.app.service('users').on('updated', message => {
        if(message._id === this.user._id)this.setUserUpdatedEvent(message);
        this.setUser(message);
      });
      this.app.service('users').on('removed', message => {   
        if(message._id === this.user._id)   
          this.login(DEFAULT_EMAIL, DEFAULT_PSW)
          .then(async(auth) => {         
            await this.setObservables();                          
          })
          .catch(error => window.alert(`Λανθασμένο login: ${error.message}`)); 
      });
    }
  
    setObservables = async() => {
       try{
          const auth = await this.app.reAuthenticate();
          const { user } = await this.app.get('authentication');
          this.setUser(user);          
          this.user && (this.setIsAuthenticated(true));  
          const tree = await this.app.service('categories').get("5d0367d6d31be01e208545a6");          
          this.setCategories(tree.youTree);    
          await this.fetchSettings();              
          const data = await this.wishlist();         
          data && (this.setFavoritesArray(data[0].items));          
          data && (this.setWishlistId(data[0]._id));  
          //console.log(this.socket.id)                        
        }catch{

        }
    }

    fetchSettings = async() => {
      const settingsArray = await this.app.service('settings').find(); 
      this.setSettings(settingsArray[0]); 
    }

    fetchChatId = () => {     
      let chatIdFromStorage = JSON.parse(localStorage.getItem('fdwa_chatId'));
      if(!chatIdFromStorage){
        chatIdFromStorage = uuidv4();
        localStorage.setItem("fdwa_chatId", JSON.stringify(chatIdFromStorage));
      }
      this.setChatId(chatIdFromStorage);
      return chatIdFromStorage;
    }
 
    login = async (email, password) => {        
        return this.app.authenticate({
            strategy: 'local',
            email,
            password
          });
    };

    //realm = null;
    
  
    logout = async () => {
      await this.app.logout();           
      this.setUser(null);
      this.setIsAuthenticated(false);
      this.setCategories(null);
      this.setSettings({});
      this.setGoToAddressHelper(false);
    }

    getAccessToken = async() => {
      return await this.app.get('authentication');
    }

    removeUser = async () => {
      const userId = this.user._id;
      await this.logout();      
      await this.login(DEFAULT_EMAIL, DEFAULT_PSW);   
      await this.setObservables();                                 
      await this.app.service("users").remove(userId);
    }

    sendApplication = async (firstname, lastname, email, password, phone) => {
      let payload = {
        email:  DEFAULT_EMAIL,
        password: DEFAULT_PSW,
        strategy: 'local'
      }
    //  return this.app.authenticate(payload).then(() => { 
    //    return new Promise(async(resolve, reject) => {        
       //   try{
            return await this.app.service('applications').create({
              firstname, lastname, email, password, phone,
              processed: false
            });
         //   return resolve(application);    
     //     }
      //   catch(error){
      //     return resolve(error);
      //    }
    //  })
     
  //  })
    }

    facebookAuth(access_token){
      return this.app.authenticate({
        strategy: "facebook",
        accessToken: access_token
      })
    }

    googleAuth(access_token){
      return this.app.authenticate({
        strategy: "google",
        accessToken: access_token
      })
    }

    getUserByEmail = async email => {
      return  await this.app.service('users')
      .find({
        query: { 
            email
        }
      })          
    }

    sendEmail = async(userId, email, firstname, lastname) => { 
      const alphabet = "abcdefghijklmnopqrstuvwxyz"
      const randomLower = alphabet[Math.floor(Math.random() * alphabet.length)]; 
      const randomUpper = alphabet[Math.floor(Math.random() * alphabet.length)].toUpperCase();      
      const random6digit = Math.floor(100000 + Math.random() * 900000).toString();
      let newPassword = randomUpper + randomLower + random6digit;
        const emailToSend = {
          userId,
          applicationId: 'FORGOT_PASSWORD',
          newPassword,
          from: `${this.settings.mailgunEmail}`,
          to: `${email}`,
          subject: 'ΝΕΟ PASSWORD',
          html: `Αγαπητέ/ή ${firstname} ${lastname} ,<br>
                  Το νέο password είναι: ${newPassword} . <br>
                  Μπορείτε να κάνετε login με το email σας και το νέο password. <br>
                  Κατόπιν μπορείτε να αλλάξετε το password με κάποιο δικό σας.` 
       };
       try{
          return await this.app.service('emails').create(emailToSend); 
        }catch(err){
          console.log('Could not send email FROM sendEmail method: ', err )
        };
    }

    async sendAgain(applicationId, firstname, lastname, email){
      let random4digit = Math.floor(1000 + Math.random() * 9000);
      const emailToSend = {
        applicationId: applicationId,
        random4digit,
        from: `${this.settings.mailgunEmail}`,
        to: `${email}`,
        subject: 'Επαναληπτική αποστολή 4ψήφιου κωδικού',
        html: `
        <html>
          <head>
            <style>
              body{  
                background-color:#c8d7e1;        
              }
              h2{
                background-color:hsl(217, 10%, 25%);
                color:#f1ebff;
                text-align: center;
              }        
              .message-container{
                border: 1px solid black;
                padding: 15px;
                text-align: left; 
                background-color: #f1f1c1;
              }
              .container{
                margin-top: 2vw;         
                padding: 2vw;
                border: 1px solid black;          
                font-size:16px;
                width: 60%;          
              }              
              @media screen and (max-width: 992px) {
                .container{
                  width: 95%;
                }
              }
            </style>
          </head>
          <body> 
            <div class="container"> 
              <h2>Αποστολή 4ψήφιου κωδικού</h2>
              <div class="message-container">   
                <p>Αγαπητέ/ή ${firstname} ${lastname} ,</p>
                <p>Ο 4ψήφιος κωδικός είναι: <strong>${random4digit}</strong> . </p>                
              </div>
            </div>
          </body>
        </html>`
     };
     try{
      await this.app.service('emails').create(emailToSend); 
    }catch(err){
      console.log('Could not send email FROM sendEmail method: ', err )
    };
    }
  

    async sendContactEmail( email, firstname, lastname, message, phone){  
      
      let htmlContent =  `
        <html>
        <head>
        <style>
        body{  
          background-color:#c8d7e1;        
        }
        h2{
          background-color:hsl(217, 10%, 25%);
          color:#f1ebff;
          text-align: center;
        }        
        .message-container{
          border: 1px solid black;
          padding: 15px;
          text-align: left; 
          background-color: #f1f1c1;
        }
        .container{
          margin-top: 2vw;         
          padding: 2vw;
          border: 1px solid black;          
          font-size:16px;
          width: 60%;          
        }
        @media screen and (max-width: 992px) {
          .container{
            width: 95%;
          }
        }
        </style>
        </head>
        <body> 
        <div class="container">       
          <h2>Νέο μήνυμα από το e-shop σας</h2>
          <div class="message-container">
            <p>Ο χρήστης ${firstname} ${lastname} ,</p>
            <p>  με email ${email} ${phone ? `και τηλέφωνο: ${phone}` : ""} ,</P>
            <p>έστειλε το εξής μήνυμα:   </p>
            <div>
                   ${message}
            </div>
          </div>
        </div>
        </body
      </html>`
     
        const emailToSend = {          
          from: `${this.settings.mailgunEmail}`,
          to: `${this.settings.email}`,
          subject: 'Μήνυμα από τη φόρμα επικοινωνίας του e-shop',
          html: htmlContent 
       };    
      return await this.app.service('emails').create(emailToSend);
    }

    newestEmail(applicationId){      
      let payload = {
        email:  DEFAULT_EMAIL,
        password: DEFAULT_PSW,
        strategy: 'local'
      }
      return this.app.authenticate(payload).then(() => { 
         return this.app.service('emaillog')   
                    .find(
                      {
                        query: { 
                            applicationId: applicationId,                   
                            $sort: {createdAt: -1},
                            $limit: 1
                        }
                    }
                    ); 
            })
    }

    createUser = async(firstname, lastname, email, password, phone) => {
        const userData = {firstname, lastname, email, password, phone, 
          role: 'customer',
          enabled: true};
        return await this.app.service('users').create(userData); 
    }

    patchUser(userId, firstname, lastname, phone){
      const payload = {firstname, lastname, phone};
      return this.app.service('users').patch(userId, payload);
    }

    patchUserAvatar(userId, avatar){     
      return this.app.service('users').patch(userId, {avatar});
    }

    _patchUser(userId, payload){
      return this.app.service('users').patch(userId, payload);
    }

    patchProduct(productId, payload){      
      return this.app.service('products').patch(productId, payload);
    }

    async patchAddresses(userId, addresses){     
      return await this.app.service('users').patch(userId, {addresses});
    }

    async updateApplicationToProcessed(id){
        return await this.app
          .service('applications')
          .patch(id, { processed: true });
    }

    async changePassword(newPassword){
      return await this.app.service('users')
        .patch(this.user._id, {password: newPassword});
    }

    getCategoriesTree(){
    return this.app.service('categories').get("5d0367d6d31be01e208545a6");
    }
    
    async productsPerCategory1(categoryId, searchText = ""){

      let query = {          
        enabledForDelivery : true ,     
        "category1.id": categoryId
      };
    
      if(searchText){
        const index = this.settings.targetLanguages?.findIndex(lang => lang.code === this.language);
        this.language === this.settings.masterLanguage ?  
          Object.assign(query,
          {name: { $regex: toString({
              mappings: diacriticsMappings
          })(searchText), $options: 'i' }}
        ) :
        Object.assign(query,
          {[`nameTranslations.${index}.text`] : { $regex: toString()(searchText), $options: 'i' }})          
      }

      return await this.app.service('products')   
        .find({
            query
        }); 
    }

    async productsPerCategory2(categoryId, searchText = ""){

      let query = {          
        enabledForDelivery : true ,     
        "category2.id": categoryId
      };
    
      if(searchText){
        const index = this.settings.targetLanguages?.findIndex(lang => lang.code === this.language);
        this.language === this.settings.masterLanguage ?  
        Object.assign(query,
          {name: { $regex: toString({
              mappings: diacriticsMappings
          })(searchText), $options: 'i' }}
        ) :
        Object.assign(query,
          {[`nameTranslations.${index}.text`] : { $regex: toString()(searchText), $options: 'i' }})   
      }
      
      return await this.app.service('products')   
        .find({
            query
        }); 
    }

    async products(searchText = ""){  

      let query = {          
        enabledForDelivery : true
      } 

      
      if(searchText){
        const index = this.settings.targetLanguages?.findIndex(lang => lang.code === this.language);
        this.language === this.settings.masterLanguage ?  
          Object.assign(query,
            {name: { $regex: toString({
                mappings: diacriticsMappings
            })(searchText), $options: 'i' }}
          ) :
          Object.assign(query,
            {[`nameTranslations.${index}.text`] : { $regex: toString()(searchText), $options: 'i' }})     
        }
  
      return await this.app.service('products')   
        .find({
            query
        }); 
    } 
    
    async _products() {
      return await this.app.service('products')   
      .find({}); 
    }

    async getProduct(productId){
      return await this.app.service('products').get(productId);
    }

    async offers(){
      return await this.app.service('products')   
      .find({
          query: { 
           offer: true,
           enabledForDelivery : true  
          }
        }); 
    }   

    createWishlist = async() => {
      const payload = {
        userId : this.user._id,
        items : this.favoritesArray
        };      
      return await this.app.service('wishlist').create(payload); 
    }

    updateWishlist = async() => {
      const payload = {
        userId: this.user._id,
        items: this.favoritesArray 
        };
      return await this.app.service('wishlist').update(this.wishlistId, payload); 
    }

    async wishlist(){
      return await this.app.service('wishlist')   
      .find({
          query: { 
           userId: this.user._id,  
          }
        }); 
    }

    async wishlistProducts(){     
      return await this.app.service('products')   
      .find({
          query: { 
            _id: {
              $in: [...this.favoritesArray] 
            },
            enabledForDelivery : true
          }
        }); 
    }

    createOrder = async(payload) => {  
      return await this.app.service('orders').create(payload); 
    }

    ordersPerUser = async() => {
      return await this.app.service('orders')   
      .find({
          query: {
            $paginate: false,
            customerId: this.user._id,
            $sort: {createdAt: -1},  
          }
        });
    }  

    tableOrdersPerUser = async() => {
      return await this.app.service('orders')   
      .find({
          query: { 
            $paginate: false,
            "order.customerId": this.user._id
          }
        });
    }

  async popularProducts(){     
    return await this.app.service('popular')   
    .find(); 
  }
    
  getSettings = async() => {
    return await this.app.service('settings')   
    .find({
      query: { 
        adminId: ADMIN_ID  
      }
    });
  }

  translate = (lang, textEL, textEN) => {
    return (this.language === lang) && textEN ?  textEN : textEL
  }

  _translate = (textEL, textTR) => {
    if(this.language === this.settings.masterLanguage)return textEL;
    else return textTR ? textTR : textEL;
  }

  _translateName = (item) => {
    if(this.language === this.settings.masterLanguage)return item?.name || "";
    else return item?.nameTranslations?.find(tr => tr?.code === this.language)?.text || item?.name;
  }

  _translateTitle = (item) => {
    if(this.language === this.settings.masterLanguage)return item?.title || "";
    else return item?.titleTranslations?.find(tr => tr?.code === this.language)?.text || item?.title;
  }

  _translateNameAttr = (item) => {
    if(this.language === this.settings.masterLanguage)return item?.name || "";
    else return item?.translations?.find(tr => tr?.code === this.language)?.text || item?.name;
  }

  _translateAddress = () => {
    if(this.language === this.settings.masterLanguage)return this.settings?.address || "";
    else return this.settings?.addressTranslations?.find(tr => tr?.code === this.language)?.text || this.settings?.address;
  }

  _translateClosedShopText = () => {
    if(this.language === this.settings.masterLanguage)return this.settings?.closedShopText || "";
    else return this.settings?.closedShopTextTranslations?.find(tr => tr?.code === this.language)?.text || this.settings?.closedShopText;
  }

  _translateShortDescription = () => {
    if(this.language === this.settings.masterLanguage)return this.settings?.shortDescription || "";
    else return this.settings?.shortDescriptionTranslations?.find(tr => tr?.code === this.language)?.text || this.settings?.shortDescription;
  }

  _translateProductShortDescription = (item) => {
    if(this.language === this.settings.masterLanguage)return item?.shortDescription || "";
    else return item?.shortDescriptionTranslations?.find(tr => tr?.code === this.language)?.text || item?.shortDescription;
  }

  _translateCategoryName = (element) => {
    if(this.language === this.settings.masterLanguage)return element?.name || "";
    else return element.options.categoryNameTranslations?.find(tr => tr?.code === this.language)?.text || element.name;
  }

   //----> Printer translations   

   translateFeature = (feature, printerLang) => {
    if(printerLang !== this.settings.masterLanguage){
      const word = feature?.translations?.find(tr => tr?.code === printerLang)?.text || feature?.name;
      if(word)return word;
    }
    return feature?.name
  }

  translateAttribute = (attribute, printerLang) => {
    if(printerLang !== this.settings.masterLanguage){
      const titleWord = attribute?.titleTranslations?.find(tr => tr?.code === printerLang)?.text || attribute?.title;
      const nameWord = attribute?.translations?.find(tr => tr?.code === printerLang)?.text || attribute?.name;
      if(titleWord && nameWord)return titleWord + ": " + nameWord + " ";
    }
    return  attribute?.title + ": " + attribute?.name + " ";
  }
  
  translateAttributeTitle = (attribute, printerLang) => {
    if(printerLang !== this.settings.masterLanguage){
      const titleWord = attribute?.titleTranslations?.find(tr => tr?.code === printerLang)?.text || attribute?.title;
      if(titleWord)return titleWord;
    }
    return  attribute?.title;
  }  

  translateAttributeName = (attribute, printerLang) => {
    if(printerLang !== this.settings.masterLanguage){
      const nameWord = attribute?.translations?.find(tr => tr?.code === printerLang)?.text || attribute?.name;
      if(nameWord)return nameWord;
    }
    return  attribute?.name;
  }  

  translateProductName = (item, printerLang) => {
    if(printerLang !== this.settings.masterLanguage){      
      const word = item?.nameTranslations?.find(tr => tr?.code === printerLang)?.text || item?.product_name;
      if(word)return word;
    }
    return item?.product_name; 
  } 

  translateShortDescription = (item, printerLang) => {
    if(printerLang !== this.settings.masterLanguage){      
      const word = item?.shortDescriptionTranslations?.find(tr => tr?.code === printerLang)?.text || item?.product_shortDescription;
      if(word)return word;
    }
    return  item?.product_shortDescription; 
  }

  //--------------------------<


 
  checkOutOfStock = (itemId, handleStock, stock, quantity, cart, weightedQuantity) => {
   
    //check stock before adding to cart and after setting quantity    
    if(handleStock){      
      const cartSimilarItems = cart.filter(i => i.product_id === itemId );
      if(cartSimilarItems){         
        const cartSimilarItemsQuantity = cartSimilarItems
          .map(csi => (csi?.product_weightedQuantity > 0 ? csi.product_quantity * csi.product_weightedQuantity : csi.product_quantity))
          .reduce((a, b) => a + b, 0)
        const newQ = +cartSimilarItemsQuantity + +(weightedQuantity > 0 ? quantity * weightedQuantity : quantity); 
        if(Math.round((newQ + Number.EPSILON) * 1000) / 1000 > stock)return true;        
      }else if(Math.round(((weightedQuantity > 0 ? quantity * weightedQuantity : quantity) + Number.EPSILON) * 1000) / 1000 > stock) return true;
    }
    return false;
  }

  createMessage = async(payload) => {      
    Object.assign(payload, { userId: this.user._id})
    this.user.firstname === "default" &&  Object.assign(payload, {chatId: this.chatId})
    return await this.app.service('messages').create(payload); 
  }

  findMessages = async() => {   
    const query = {$sort: {createdAt: 1}};
    this.user.firstname === "default" ? Object.assign(query, {chatId: this.chatId}) 
    : Object.assign(query, {userId: this.user._id});
    return await this.app.service('messages')   
    .find({query});
  }

  getTable = async(tableId) => {
    return await this.app.service('tables').get(tableId);
  }

  _patchTable = async(tableId, order) => {     
    return await this.app.service('tables').patch(tableId, {order});
  }

  patchTable = async(id, payload) => {
    return await this.app    
    .service('tables')
    .patch(id, payload);
  }

  bookingTables = async() => {
    return await this.app      
    .service('tables')
    .find({
      query:{                
       bookingDisabled: false          
      }
    })
  }

  createLocalPrint = async (payload) => {      
    return await this.app 
      .service('localprint')
      .create(payload); 
  }

  createCompany = async(payload) => {
    return await this.app      
    .service('companies')    
    .create(payload);
  }

  updateCompany = async(id, payload) => {
    return await this.app      
    .service('companies')    
    .update(id, payload);
  }

  findCompany = async(afm) => {
    return await this.app      
    .service('companies')
    .find({
      query:{                
       afm          
      }
    })  
  }

  getCompany = async(companyId) => {
    return await this.app      
    .service('companies')    
    .get(companyId);
  }

  createSoap = async(afm) => {
    return await this.app      
    .service('soap')    
    .create({afm});
  }
  async patchCompanyAddresses(companyId, addresses){     
    return await this.app.service('companies').patch(companyId, {addresses});
  }  

  async coupons(){
    return await this.app.service('coupons')   
    .find({
        query: { 
         active: true,
        }
      }); 
  }

  getDeltioCounter = async() => {
    return await this.app      
    .service('counters')
    .get("deltio")
  }

  patchCounters = async(id, payload) => {      
    return await this.app
      .service('counters')
      .patch(id, payload);
  }

  postToMyData = async(payload) => {      
    return await this.app
      .service('mydata')
      .create(payload); 
  }

  createLogEntry = async (payload) => {      
    return await this.app
      .service('emaillog')
      .create(payload); 
  }

}

export default new FeathersStore()