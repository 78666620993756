import React, {useEffect, useState} from 'react';
import { CSSTransition } from 'react-transition-group';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@mui/icons-material/Check';
import { inject, observer } from "mobx-react";
import _useTranslate from '../hooks/_useTranslate';

const LoyaltyModal = ({ show, onClose, feathersStore}) =>{ 

  const greenColored = {color: "hsl(151, 100%, 28%)", fontSize: 35, marginRight: 1}

  let common = _useTranslate(feathersStore.language, "common");

  useEffect(() => {  
    let timeoutId = null; 
  if(show){
    timeoutId = setTimeout(onClose, 30000);
  } 
  if (timeoutId){
     return () => { clearTimeout(timeoutId) }
  }else{
    return () => null
  }
  
  },[show]) 
    
  return(
    <>
    <div className="loyalty-alert-container">
      <CSSTransition
        in={show}
        timeout={300}
        classNames="my-alert"
        unmountOnExit
      >
        <div className="loyalty-alert">
          <div className="copy-title-text">         
            <CheckCircleIcon sx={greenColored}/>          
            {common.loyaltyTitle}
          </div> 
          <div className="alert-body-text">{`${common.loyaltyCaption} : ${feathersStore.currency}`}</div>
          <div className="alert-body-text">{common.loyaltySubCaption}</div>          
          <div className="copy-row">            
            <div className="go-cart" onClick={onClose} >{`${common.exit} `} </div>  
          </div>        
          <CloseIcon className="alert-close-icon"  onClick={onClose}/>
        </div>
      </CSSTransition>
    </div>
  </>
  )
}

export default inject('feathersStore')(observer(LoyaltyModal));